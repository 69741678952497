export const firebaseConfig = {
    apiKey: "AIzaSyB0W8UzRq3iwbbTdJldxWH5WD4SfAzUcRw",
    authDomain: "messaging-staging-379.firebaseapp.com",
    projectId: "messaging-staging-379",
    storageBucket: "messaging-staging-379.appspot.com",
    messagingSenderId: "444782178878",
    appId: "1:444782178878:web:3d2ce151ee0ba54db4d76f"
  };

export const vapidKey = 'BIkBIL7S_nQv3qyoBCTTXXsfNW5vTadYZOOBNdQ9GRGTECbzxQGLZFjCnC5ICjHrGFyy0n3pNlioQnQ_8cdPk4o';
