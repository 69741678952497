import { initializeApp } from "@firebase/app";
import { onBackgroundMessage, getMessaging } from "@firebase/messaging/sw";
import { firebaseConfig } from './config';

const fbApp = initializeApp(firebaseConfig);
const messaging = getMessaging(fbApp);

console.log(messaging);

self.addEventListener('activate', () => {
  onBackgroundMessage(messaging, (payload) => {
    console.log('[firebase-messaging-sw.js] Received background message ', payload);
  });
});
